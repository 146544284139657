import {SliderElem} from '../../types';
import brokenLinksImg from '../../img/home-tools/broken-link.png';
import metaTagAnalyzerImg from '../../img/home-tools/meta-tag-analyzer.png';
import pageAnalyzerImg from '../../img/home-tools/page-analyzer.png';
import sslCheckerImg from '../../img/home-tools/ssl-checker.png';
import techStackImg from '../../img/home-tools/tech-stack.png';
import w3cCheckerImg from '../../img/home-tools/w3c-checker.png';
import whoisToolImg from '../../img/home-tools/whois.png';
import ipLookup from '../../img/home-tools/ip-lookup.png';
import speedTool from '../../img/home-tools/page-speed.png';
import uptimeMonitoringImg from '../../img/home-tools/uptime-monitoring.png';

export const language = 'en';

export const CHROME_EXTENTSION_URL =
  'https://chrome.google.com/webstore/detail/hexometer-tool/hikinokpbcadaepfkacpnkhbdjfpfcgc';
export const FIREFOX_EXTENSION_URL = 'https://addons.mozilla.org/en-US/firefox/addon/hexometer-tool/';

export const URL_RE =
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g;
export const DOMAIN_RE =
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const IP_REGEX =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i;

export const VALID_PASSWORD_REGEX =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const MAX_NUMBER_OF_SCANS = 1;
export const MAX_NUMBER_OF_SCANS_PROPERTY = 2;

export const isValidUrl = (url: string) => URL_RE.test(url);
export const isValidDomain = (domain: string) => DOMAIN_RE.test(domain);
export const isValidIP = (url: string): boolean => IP_REGEX.test(url);

export const FooterMenuItems = (isLoggedIn: boolean) => {
  const footerMenuItems = [
    {
      name: 'Tools',
      subItems: [
        {name: 'Domain Whois', linkTo: 'https://hexomatic.com/automation/discover-whois/'},
        {name: 'Page Analyzer', linkTo: '/page-analyzer'},
        {name: 'Stack Checker', linkTo: 'https://hexomatic.com/automation/discover-tech-stack/'},
        {name: 'Broken Link Checker', linkTo: '/broken-links'},
        {name: 'Meta Tag Analyzer', linkTo: 'https://hexomatic.com/automation/seo-meta-tags/'},
        {name: 'W3C Validator', linkTo: '/w3c-validator'},
        {name: 'SSL Certificate Checker', linkTo: '/ssl-certificate-checker'},
        {name: 'IP Lookup', linkTo: '/ip-lookup'},
        {name: 'Page Speed Scanner', linkTo: '/page-speed-scanner'},
        {name: 'Uptime Monitoring', linkTo: '/uptime-monitoring'},
      ],
    },
    {
      name: 'Menu',
      subItems: [
        {name: 'Hexowatch - Monitoring', linkTo: 'https://hexowatch.com/'},
        {name: 'Hexomatic - Data Scraping', linkTo: 'https://hexomatic.com/'},
        // {name: 'How does it work?', linkTo: '/about'},
        // {
        //   name: 'E-Commerce and retail',
        //   linkTo: '/ecommerce-and-retail',
        //   className: 'nav-link',
        // },
        // {name: 'Agencies', linkTo: '/agencies', className: 'nav-link'},
        // {
        //   name: 'Online publishers',
        //   linkTo: '/online-publishers',
        //   className: 'nav-link',
        // },
        {name: 'Team', linkTo: 'https://hexact.io/leadership/'},
        // {name: 'Roadmap', linkTo: '/roadmap'},
        // {
        //   name: 'Changelog',
        //   linkTo: 'https://hexometer.com/academy/hexometer-changelog/',
        // },
        {name: 'Affiliate program', linkTo: '/affiliate'},
        {name: 'Software integrations', linkTo: '/software-integrations'},
        // {name: 'Pricing', linkTo: '/pricing'},
        {name: 'Top Charts', linkTo: '/most-popular-tech-stacks'},
        {
          name: 'Academy',
          linkTo: 'https://hexowatch.com/academy/',
        },
        {
          name: 'FAQ',
          linkTo: '/faq',
        },
        {
          name: 'Career',
          linkTo: 'https://hexact.io/careers',
          target: '_blank',
        },
        {name: 'Contact us', linkTo: '/contact'},
      ],
    },
  ];

  if (!isLoggedIn) {
    footerMenuItems[1].subItems.push(
      // {name: 'Create Account', linkTo: '/#registration'},
      {name: 'Login', linkTo: `/#login`},
    );
  }

  return footerMenuItems;
};

export const MenuCardItems = [
  {
    text: 'Purchasing Online',
    href: '#purchase',
  },
  {
    text: 'Product 2',
    href: '#product2',
  },
  {
    text: 'Product 3',
    href: '#product3',
  },
];

export const setCookie = (name: string, value: string, days = 365, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

export const getCookie = (c_name: string) => {
  let c_start, c_end;
  if (document && document.cookie && document.cookie.length > 0) {
    c_start = document.cookie.indexOf(c_name + '=');
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      c_end = document.cookie.indexOf(';', c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
};

export const getMainPath = (str: string, searchingRoute: string) => {
  /**
   * This function is checking if we are on our searching route or not
   */
  const regex = /^\/([^?\/]+)/;
  const match = str.match(regex);
  if (match) {
    return match[1] === searchingRoute;
  } else {
    return false;
  }
};

export const ToolsSliderItems: Array<SliderElem> = [
  {
    name: 'TECH_STACK_CHECKER_TOOL',
    subTitle: 'TECH_STACK_CHECKER_TOOL_SUBTITLE',
    image: techStackImg,
    path: 'https://hexomatic.com/automation/discover-tech-stack/',
  },
  {
    name: 'DOMAIN_WHOIS_TOOL',
    subTitle: 'DOMAIN_WHOIS_TOOL_SUBTITLE',
    image: whoisToolImg,
    path: 'https://hexomatic.com/automation/discover-whois/',
  },
  {
    name: 'META_TAG_ANALYZER_TOOL',
    subTitle: 'META_TAG_ANALYZER_TOOL_SUBTITLE',
    image: metaTagAnalyzerImg,
    path: 'https://hexomatic.com/automation/seo-meta-tags/',
  },
  {
    name: 'IP_LOOKUP_TOOL',
    subTitle: 'IP_LOOKUP_TOOL_SUBTITLE',
    image: ipLookup,
    path: '/ip-lookup',
  },
  {
    name: 'BROKEN_LINKS_TOOL',
    subTitle: 'BROKEN_LINKS_TOOL_SUBTITLE',
    image: brokenLinksImg,
    path: '/broken-links',
  },
  {
    name: 'UPTIME_MONITORING_TOOL',
    subTitle: 'UPTIME_MONITORING_TOOL_SUBTITLE',
    image: uptimeMonitoringImg,
    path: '/uptime-monitoring',
  },
  {
    name: 'PAGE_SPEED_SCANNER_TOOL',
    subTitle: 'PAGE_SPEED_SCANNER_TOOL_SUBTITLE',
    image: speedTool,
    path: '/page-speed-scanner',
  },
  {
    name: 'PAGE_ANALYZER_TOOL',
    subTitle: 'PAGE_ANALYZER_SUBTITLE',
    image: pageAnalyzerImg,
    path: '/page-analyzer',
  },
  {
    name: 'SSL_TOOL',
    subTitle: 'SSL_TOOL_SUBTITLE',
    image: sslCheckerImg,
    path: '/ssl-certificate-checker',
  },
  {
    name: 'W3C_VALIDATOR_TOOL',
    subTitle: 'W3C_VALIDATOR_TOOL_SUBTITLE',
    image: w3cCheckerImg,
    path: '/w3c-validator',
  },
];

/**
 * Faq paths
 */

export const tabs = [
  {
    name: 'FAQ',
    path: '/faq',
  },
  {
    name: 'Glossary',
    path: '/glossary',
  },
  {
    name: 'HTTP status codes',
    path: '/http-status-codes',
  },
];

export const stackList = [
  'Whois API',
  'Tech Stack API',
  'Domain Availability API',
  'SSL Lookup API',
  'DNS Lookup API',
  'Subdomain Lookup API',
  'IP Geolocation API',
  'E-mail verification API',
  'Screenshot API',
];

export const pricingRange = [
  100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
];
