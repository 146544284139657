import './src/styles/bootstrap.min.css'
import './src/styles/responsive.scss'
import './src/styles/slick-theme.css'
import './src/styles/slick.min.css'

import React from 'react';
import { ApolloWrapper } from './src/graphql';
import HelmetTitle from './src/components/helmet';

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloWrapper>
      {element}
    </ApolloWrapper>
  );
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <HelmetTitle />
      {element}
    </>
  )
};
