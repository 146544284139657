import React, {FC, useEffect} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Helmet} from 'react-helmet';
import {Location} from '@reach/router';
import {HeaderDataItem, HelmetHeaderProps} from '../../types';
import {templateRender} from '../translations';
import ogImg from '../../../static/Hexometer-AI-sidekick.png';
import ogImgCareer from '../../../static/AWESOME.jpg';
import ogImgV2 from '../../../static/Hexometer-launch.png';

const paramIndicator = '$param';

const query = graphql`
  query {
    allHexometerHeaderData {
      nodes {
        path
        title
        metaTags {
          name
          content
        }
      }
    }
  }
`;

const generateData = (location: string, headerItems: HeaderDataItem[]): HeaderDataItem | undefined => {
  if (headerItems && headerItems.length > 0) {
    if (location === '/') {
      return headerItems.find((item: HeaderDataItem) => item.path === '/');
    }

    if (location[location.length - 1] === '/') {
      location = location.substr(0, location.length - 1);
    }

    const locationSplit = location.split('/');

    return headerItems.find((item: HeaderDataItem) => {
      const pathSplit = item.path.split('/');
      if (locationSplit.length !== pathSplit.length) {
        return false;
      }

      for (let index = 0; index < pathSplit.length; index++) {
        const pathPart = pathSplit[index];
        if (pathPart === paramIndicator) continue;
        if (pathPart !== locationSplit[index]) {
          return false;
        }
      }

      return true;
    });
  }
};

let defaultData: HeaderDataItem | undefined = undefined;

export default ({}: FC<HelmetHeaderProps>) => {
  const data = useStaticQuery(query);

  useEffect(() => {
    defaultData = generateData('/', data.allHexometerHeaderData.nodes);
  }, [data]);

  const generatedTwitterMetaKeyWords = (
    metaTags: Array<{name: string; content: string}>,
    context: object,
    title: string,
  ) => {
    const totalTwitterMetaTags = [
      {
        name: 'twitter:site',
        content: '@hexactinc',
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
    ];

    metaTags.map((metaTag: {name: string; content: string}) => {
      if (metaTag.name === 'description') {
        totalTwitterMetaTags.push({
          name: 'twitter:description',
          content: templateRender(metaTag.content, context),
        });
        totalTwitterMetaTags.push({
          name: 'description',
          content: templateRender(metaTag.content, context),
        });
      }
    });

    return totalTwitterMetaTags.map((metaTag: {name: string; content: string}) => ({
      name: metaTag.name,
      //key: metaTag.name,
      content: templateRender(metaTag.content, context),
    }));
  };

  const generatedMetaKeyWords = (metaTags: Array<{name: string; content: string}>, context: object, title: string) => {
    const totalMetaTags = [
      {
        name: 'og:title',
        content: title,
      },
      {
        name: 'og:type',
        content: 'website',
      },
      {
        name: 'og:locale',
        content: 'en_US',
      },
    ];

    metaTags.map((metaTag: {name: string; content: string}) => {
      if (metaTag.name === 'description') {
        totalMetaTags.push({
          name: 'og:description',
          content: templateRender(metaTag.content, context),
        });
      }
    });

    return totalMetaTags.map((metaTag: {name: string; content: string}) => ({
      name: metaTag.name,
      //key: metaTag.name,
      content: templateRender(metaTag.content, context),
    }));
  };

  return (
    <Location>
      {({location}) => {
        if (!data) {
          return null;
        }

        const generatedData: HeaderDataItem | undefined =
          generateData(location.pathname, data.allHexometerHeaderData.nodes) || defaultData;
        if (generatedData) {
          const pathSplit = location.pathname.split('/');
          const context = {
            first: pathSplit[0],
            last: pathSplit[pathSplit.length - 1],
            split: pathSplit,
          };
          return (
            <Helmet title={templateRender(generatedData.title, context)}>
              <html lang="en" />
              {!location.pathname.includes('/reset-password') && (
                <script type="text/javascript">
                  {`(function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                  j.async = true;
                  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-5CVGX45')`}
                </script>
              )}

              {location.pathname.includes('/faq') && (
                <script type="application/ld+json">
                  {`{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [{
                      "@type": "Question",
                      "name": "What is Hexometer?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Hexometer is your AI sidekick that works behind the scenes, monitoring your website and marketing 24/7. Detect downtime and fix page speed performance issues, security vulnerabilities, page & JS errors, email deliverability, SEO optimization or server configuration problems before they burn a hole in your pocket. Get started in minutes - no code changes required."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "How does uptime monitoring work?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Hexometer provides 24/7 peace of mind by continuously monitoring all your website pages for: Uptime HTTP responses for every page on your website DNS records SSL certificates Security headers"
                      }
                    },{
                      "@type": "Question",
                      "name": "How does Performance monitoring work?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Hexometer monitors your most important pages daily, helping you track and improve your performance via actionable insights. Analyze website performance from desktop, tablet and mobile devices Monitor page speed Get actionable insights to fix performance issues Check how your home page display in different devices"
                      }
                    },{
                      "@type": "Question",
                      "name": "How does Hexometer check my website for errors and broken pages?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Maintaining a website is a full-time job; every website update, WordPress version change, plugin update, website modification or 3rd party script has the potential to break things. Whilst a visual bug might lower your conversion rate, a broken functionality like a shopping cart is much more costly. Hexometer checks all your web pages for broken links, server errors, images that don't display and JS errors so you can pinpoint and fix problems before they affect your business."
                      }
                    },{
                      "@type": "Question",
                      "name": "How does Hexometer help with my SEO rankings?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Hexometer performs an SEO audit of all your pages on a weekly or daily basis depending on your plan, checking your website for on-site issues that can affect your search engine rankings as well as identify areas for optimization. Some of the factors we check include: Title and description meta tags Duplicate content issues Broken links Header Structure Sitemap issues Open Graph optimizations"
                      }
                    },{
                      "@type": "Question",
                      "name": "What security monitoring does Hexometer provide?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Our AI assistant monitors your homepage for known malware and malicious code. We also check your security headers, SSL certificate and if your website is blacklisted by the leading security authorities."
                      }
                    },{
                      "@type": "Question",
                      "name": "Which notification channels does Hexometer support to let you know when something goes wrong?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Hexometer can alert you when an issue happens via: Email Telegram Slack Discord Trello Hexometer is also integrated with Google sheets, also with Zapier, which enables you to get alert notifications to up to 2000 different apps in the Zapier marketplace."
                      }
                    },{
                      "@type": "Question",
                      "name": "How to filter Hexometer traffic in Google Analytics?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Select the Property in your Google Analytics account and click Filters. Click Add filter Type Filter name Select Custom - Exclude Select Browser in the Filter Field Type Hexometer Save"
                      }
                    },{
                      "@type": "Question",
                      "name": "What is Meta Tags Scanner?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Website Meta Tags Analyzer scans your web page's Title and Description Tags and checks for compliance to best practice."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is Domain Whois tool?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Domain Whois service allows you to get any public information about a domain name. When you enter a domain name into the Hexometer Domain Whois Lookup Service search bar, data from the Whois record, as well as other information available for Hexometer, are returned in a result page. Whois records can contain information about the registrar, domain name owner and contact information, the domain's name server, and the dates of registration and expiration."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is the Broken Links tool?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Broken Link Checker Tool helps you to scan and detect the dead (or 'rotten') links on your website for you to fix them."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is Tech Stack Checker?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Website Technology Stack Checker Tool helps you to detect what kind of technologies are used in the mentioned website, including coding languages, CMS, marketing tools, e-commerce solutions and more."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is W3C Validator?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "W3C Markup Validation Tool checks the markup validity of Web documents in HTML, XHTML, SMIL, MathML, etc. You can read more at: https://en.wikipedia.org/wiki/World_Wide_Web_Consortium"
                      }
                    },{
                      "@type": "Question",
                      "name": "What is SSL Checker?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "SSL Certificate Checker tool will help you diagnose whether the website has an SSL Certificate or not, and will retrieve any public data connected with it. You can verify the SSL certificate, to make sure it is correctly installed, valid, trusted and doesn't give any errors to any of your users."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is Page Analyzer?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Page Assets Analyzer helps you to scan a web page and detect every JS, CSS, Image, and other file size and count, so you could beat the upcoming issues."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is IP Lookup tool?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "IP Address Lookup tool helps to find out the country, owner and more available data about IP address."
                      }
                    },{
                      "@type": "Question",
                      "name": "What is Page Speed Scanner?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Page Load Analyzer helps to detect website load performance figures for future optimization"
                      }
                    }]
                  }`}
                </script>
              )}
              <link rel="canonical" href={`https://hexometer.com${location.pathname}`} />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180-precomposed.png" />
              <link rel="icon" type="image/png" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#16ac59" />
              <meta name="msapplication-TileColor" content="#16ac59" />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <meta name="msapplication-config" content="/browserconfig.xml" />

              {generatedMetaKeyWords(generatedData.metaTags, context, templateRender(generatedData.title, context)).map(
                item => (
                  <meta key={item.name} property={item.name} content={item.content} />
                ),
              )}
              {generatedTwitterMetaKeyWords(
                generatedData.metaTags,
                context,
                templateRender(generatedData.title, context),
              ).map(item => (
                <meta key={item.name} name={item.name} content={item.content} />
              ))}
              <meta property="og:url" content={`https://hexometer.com${location.pathname}`} />

              <meta
                property="og:image"
                content={`https://hexometer.com${
                  location &&
                  location.pathname &&
                  (location.pathname.indexOf('career') > -1
                    ? ogImgCareer
                    : location.pathname.indexOf('notifyme') > -1 || location.pathname.indexOf('launch') > -1
                    ? ogImgV2
                    : ogImg)
                }`}
              />
              <meta
                property="twitter:image"
                content={`https://hexometer.com${
                  location?.pathname?.indexOf('career') > -1 ? ogImgCareer : ogImg
                }`}
              />
            </Helmet>
          );
        }

        return null;
      }}
    </Location>
  );
};
