import React, {FC, memo} from 'react';
import {StaticQuery, graphql} from 'gatsby';
import UT from 'underscore.template';
import ReactMarkdown from 'react-markdown';
import remarkHeadingId from 'remark-heading-id';

export const trObject = (translations: Array<{key: string; value: string}>, name: string) => {
  const foundTranslation = translations.find(item => item.key === name);
  return foundTranslation ? foundTranslation.value : name;
};

export const templateRender = (text: string, context: any): string => {
  const rpText = text.replace('$%', '<%').replace('%$', '%>');
  if (context) {
    try {
      return UT(rpText)(context);
    } catch (e) {}
  }

  return text;
};

const query = graphql`
  query {
    allHexometerKeywords {
      totalCount
      nodes {
        key
        value
        createdAt
        updatedAt
      }
    }
  }
`;

const Translate: FC<any> = memo(({name, children, context}) => (
  <StaticQuery
    query={query}
    render={data => {
      let translatedText = name;
      if (data?.allHexometerKeywords?.nodes && data.allHexometerKeywords.nodes.length > 0) {
        translatedText = templateRender(trObject(data.allHexometerKeywords.nodes, name), context);
      }

      if (children && typeof children === 'function') {
        return children(translatedText);
      }
      
      return (
        <ReactMarkdown
          sourcePos={translatedText}
          rawSourcePos={translatedText}
          unwrapDisallowed={!translatedText.includes('.')}
          disallowedElements={!translatedText.includes('.') ? ['p'] : []}
          skipHtml={false}
          remarkPlugins={[remarkHeadingId]}
          rehypePlugins={[remarkHeadingId]}
        >
          {translatedText}
        </ReactMarkdown>
      );
    }}
  />
));

export {Translate};
