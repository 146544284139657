import Cookies from 'js-cookie';
import dayjs, {Dayjs} from 'dayjs';
import {navigate} from 'gatsby';
import md5 from 'md5';
import {MAX_NUMBER_OF_SCANS, MAX_NUMBER_OF_SCANS_PROPERTY} from './constants';
import CountryCodes from './countries';
import {CountryInfoType} from '../types';

const CookieDomains = ['37.186.119.181', 'localhost', '.hexometer.com', '.hexowatch.com'];

// TODO: REMOVE THIS!!!!!!!!
// TMP FIX FOR COOKIES
setTimeout(() => {
  // @ts-ignore
  if (
    global.window &&
    global.window.location &&
    global.window.location.hostname &&
    global.window.location.hostname.indexOf('hexometer.com') > -1
  ) {
    Cookies.remove('__session', {
      domain: undefined,
    });
  }
}, 100);

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const setRawCookie = (name: string, value: string) =>
  CookieDomains.map(domain => {
    Cookies.set(name, value),
      {
        expires: 30,
        domain: domain,
        sameSite: 'Strict',
      };
  });

export const getCookies = (): any => {
  let cookiesStr: string | undefined = undefined;
  let cookies = {};
  for (let domain of CookieDomains) {
    cookiesStr = Cookies.get('__session', {domain: domain});
    if (cookiesStr) {
      try {
        cookies = JSON.parse(decodeURIComponent(cookiesStr));
      } catch (e) {}
    }
  }

  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({...getCookies(), [key]: value}), {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeCookiePolicyNew = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicyNew = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const setImpactClickIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__irClickId', value, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const setImpactMediaPartnerIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__mediaPartnerId', value, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeImpactClickIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__irClickId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactMediaPartnerIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__mediaPartnerId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDocumentReferrer = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('document_referrer', value, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeDocumentReferrer = () => {
  CookieDomains.map(domain => {
    Cookies.remove('document_referrer', {
      expires: 30,
      domain: domain,
    });
  });
};

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setCookieDownloadIgnore = () => setCookie('download-panel', 'closed');
export const getCookieDownloadIgnore = () => getCookie('download-panel');

export const setCaptchaResponseToken = (token: string) =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.setItem('toolsCaptchaResponseToken', token);

export const getCaptchaResponseToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.getItem('toolsCaptchaResponseToken');

export const deleteCaptchaResponseToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.removeItem('toolsCaptchaResponseToken');

export const setToolsToken = (token: string) =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.setItem('toolsSessionToken', token);

export const getToolsToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.getItem('toolsSessionToken');

export const deleteToolsToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.removeItem('toolsSessionToken');

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};
export const setUserToken = (token: string) => setCookie('user-token', token);
export const setUserHash = (hash: string) => setCookie('user-hash', hash);
export const getUserToken = (): string | undefined => getCookie('user-token');
export const getUserHash = (): string | undefined => getCookie('user-hash');
export const removeUserToken = () => setCookie('user-token', null);
export const removeUserHash = () => setCookie('user-hash', null);

export const setNewUser = (isNew: boolean) => setCookie('new-user', isNew);
export const getNewUser = (): string | undefined => getCookie('new-user');
export const removeNewUser = () => removeCookie('new-user');

export const setEasyDmarkUser = (val: boolean) => setCookie('easy-dmark-user', val);
export const getEasyDmarkUser = (): string | undefined => getCookie('easy-dmark-user');
export const removeEasyDmarkUser = () => removeCookie('easy-dmark-user');

export const setLTDPromo249 = (show: string) => localStorage.setItem('ltd-249', show);
export const getLTDPromo249 = (): string => localStorage.getItem('ltd-249') || '';
export const removeLTDPromo249 = () => localStorage.removeItem('ltd-249');

export const setUserEmail = (email: string) => setCookie('user-email', email);
export const getUserEmail = (): string | undefined => getCookie('user-email');
export const removeUserEmail = () => setCookie('user-email', null);

export const setUserName = (name: string) => setCookie('user-name', name);
export const getUserName = (): string | undefined => getCookie('user-name');
export const removeUserName = () => setCookie('user-name', null);

export const setPromoHeader = () => setCookie('holidays-sale-header', 'true');
export const getPromoHeader = (): string => getCookie('holidays-sale-header');
export const removePromoHeader = () => setCookie('holidays-sale-header', '');

export const setCookiePolicy = () => setCookie('user', 'registered');
// @ts-ignore
export const getCookiePolicy = () => (!global.window ? 'registered' : getCookie('user'));

export const setRefreshToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

// export const getGravatar = (email: string, size?: number) => {
//   size = size || 80;
//   return 'http://www.gravatar.com/avatar/' + md5(email) + '?s=' + size + '&d=monsterid';
// };

export const getScanCount = () => parseInt(getCookie('scan-count') || '0');
export const getPropertyScanCount = () => parseInt(getCookie('property-scan-count') || '0');
export const resetPropertyScanCount = () => setCookie('property-scan-count', '0');
export const ignorePropertyScanCount = () => setCookie('property-scan-count', '-1');

export const incrementToolRequest = (incrementValue = 1) => {
  const count = getScanCount();
  setCookie('scan-count', (count + incrementValue).toString());
  incrementToolPropertyRequest(incrementValue);
};

export const incrementToolPropertyRequest = (incrementValue = 1) => {
  const count = getPropertyScanCount();
  // if we have -1 then user pushed Ignore button
  if (count < 0) return;

  setCookie('property-scan-count', (count + incrementValue).toString());
};

export const checkScanLimit = () => getUserToken() || getScanCount() <= MAX_NUMBER_OF_SCANS;

export const checkPropertyScanLimit = () => getPropertyScanCount() <= MAX_NUMBER_OF_SCANS_PROPERTY;

export const savePricingPackage = (packageName: string) => setCookie('pricing-package', packageName);
export const getPricingPackage = () => getCookie('pricing-package');

export const saveAnnual = (annual: string) => setCookie('pricing-package-annual', annual);
export const getAnnual = () => getCookie('pricing-package-annual');

export const saveUptimePageUrl = (url: string) => setCookie('uptime-url', url);
export const getUptimePageUrl = () => getCookie('uptime-url');

export const NoFollowLink = (url: string) => () => window.open(url, '_blank', 'noopener');

const getDateDiff = (
  oldDate: Dayjs,
  newDate: Dayjs,
): {
  years: number;
  months: number;
  days: number;
} => {
  const diff = {
    years: newDate.diff(oldDate, 'year'),
    months: 0,
    days: 0,
  };
  oldDate = oldDate.add(diff.years, 'year');
  diff.months = newDate.diff(oldDate, 'month');
  oldDate.add(diff.months, 'month');

  oldDate = oldDate.add(diff.months, 'month');
  diff.days = newDate.diff(oldDate, 'day');

  return diff;
};

const getDiffInDays = (
  oldDate: Dayjs,
  newDate: Dayjs,
): {
  days: number;
} => {
  const diff = {
    days: 0,
  };
  diff.days = newDate.diff(oldDate, 'day');

  return diff;
};

export const SSLTitle = (isoDate: string): string => {
  const diff = getDateDiff(dayjs(), dayjs(isoDate));
  if (diff.years === 0 && diff.months === 0 && diff.days === 0) {
    return 'SSL certificate expires:';
  } else if (diff.years < 0 || diff.months < 0 || diff.days < 0) {
    return 'SSL certificate expired:';
  } else {
    return 'SSL certificate expires in:';
  }
};

export const TillDate = (isoDate: string): string => {
  const diff = getDateDiff(dayjs(), dayjs(isoDate));
  if (diff.years < 0 || diff.months < 0 || diff.days < 0) {
    return `${
      diff.years !== 0 ? parseInt((diff.years * -1).toString()) + ' year' + `${diff.years === -1 ? '' : 's'}` : ''
    } ${
      diff.months !== 0 ? parseInt((diff.months * -1).toString()) + ' month' + `${diff.months === -1 ? '' : 's'}` : ''
    } ${diff.days !== 0 ? parseInt((diff.days * -1).toString()) + ' day' + `${diff.days === -1 ? '' : 's'}` : ''}`;
  } else if (diff.years === 0 && diff.months === 0 && diff.days === 0) {
    return 'Today';
  } else {
    return `${diff.years !== 0 ? parseInt(diff.years.toString()) + ' year' + `${diff.years === 1 ? '' : 's'}` : ''} ${
      diff.months !== 0 ? parseInt(diff.months.toString()) + ' month' + `${diff.months === 1 ? '' : 's'}` : ''
    } ${diff.days !== 0 ? parseInt(diff.days.toString()) + ' day' + `${diff.days === 1 ? '' : 's'}` : ''}`;
  }
};

export const GetCountry = (countryCode: string): CountryInfoType => CountryCodes[countryCode];

export const findHostName = (href: string) => {
  if (href.indexOf('http') !== 0) {
    href = 'http://' + href;
  }
  const url = document.createElement('a');
  url.href = href;
  return url.hostname;
};
export const isValidSSL = (subjectaltname: string[], url: string) => {
  const hostName = findHostName(url);
  return subjectaltname.some(el => {
    if (el[0] === '*') {
      const regexp = new RegExp(`^(\\w*(${el.slice(1)})\\b)`, 'g');
      const result = regexp.test(hostName);
      if (result) {
        return true;
      }
    }
    return el === hostName;
  });
};

export const cleanURLSpace = (name: string) => name && name.replace(/\s+/g, '+');
export const getBackURLSpace = (name: string) => name && name.replace(/\+/g, ' ');

export const toggleLastURL = () => {
  if (window.history.length === 1) {
    navigate('/');
  } else {
    window.history.back();
  }
};

export const toggleCloseLoginModalURL = () => {
  navigate('/');
};

export const gotToWithReplace = (pathName: string) => {
  window.history.replaceState({}, '', pathName);
  if (pathName === '/#login') {
    navigate(pathName, {state: {goBack: true}});
  } else {
    navigate(pathName);
  }
};

const generateDate = (
  newDate: string,
  oldDate: string,
): {
  years: string;
  months: string;
  days: string;
  diffInDays: number;
} => {
  const diff = getDateDiff(dayjs(oldDate), dayjs(newDate));
  const diffInDays = getDiffInDays(dayjs(oldDate), dayjs(newDate)).days;
  const years: string = diff.years && diff.years >= 1 ? `${diff.years} year${diff.years === 1 ? '' : 's '} ` : '';
  const months: string = diff.months && diff.months >= 1 ? `${diff.months} month${diff.months === 1 ? '' : 's '} ` : '';
  const days: string =
    diff.days === 0
      ? 'Today'
      : diff.days >= 1
      ? `${diff.days.toFixed()} day${diff.days === 1 ? '' : 's '} `
      : diff.days < 0
      ? `${(diff.days * -1).toFixed()} day${diff.days === -1 ? '' : 's '} ago`
      : '';
  return {
    years,
    months,
    days,
    diffInDays,
  };
};

export const isoDateToHtmlDate = (
  isoDate: string,
  type: string,
  withDots?: boolean,
  subType?: string,
): string | number => {
  let date = new Date(isoDate);
  let dtString = '';
  let monthString = '';
  const newDate: string = new Date().toISOString();
  if (type === 'registeredAt') {
    if (date.getDate() < 10) {
      dtString = '0' + date.getDate();
    } else {
      dtString = String(date.getDate());
    }
    if (date.getMonth() + 1 < 10) {
      monthString = '0' + Number(date.getMonth() + 1);
    } else {
      monthString = String(date.getMonth() + 1);
    }
    return withDots
      ? `${monthString}.${dtString}.${date.getFullYear()}`
      : `${monthString} / ${dtString} / ${date.getFullYear()}`;
  } else if (type === 'expiresAt' && subType === 'cardValue') {
    const diff = generateDate(isoDate, newDate);
    return `${diff.years}${diff.months}${diff.days}`;
  } else if (type === 'expiresAt' && subType === 'checkValue') {
    const diff = generateDate(isoDate, newDate);
    return diff.diffInDays;
  } else if (type === 'lastModified') {
    const diff = generateDate(newDate, isoDate);
    return `${diff.years}${diff.months}${diff.days} ago`;
  } else {
    const diff = generateDate(newDate, isoDate);
    return `${diff.years}${diff.months}${diff.days}`;
  }
};

export function parseJSONRecords(dnsString?: string): any {
  try {
    return JSON.parse(dnsString || '');
  } catch (e) {
    return null;
  }
}

export const checkDevelopmentServer = (): boolean => {
  const hostsToIgnore = ['localhost', 'web.bayburtsyan.com', 'staging.hexometer.com'];

  // @ts-ignore
  if (global.window) {
    const ignoredHost = hostsToIgnore.find(h => window.location.hostname.indexOf(h) > -1);
    return !!ignoredHost;
  }

  return false;
};

export const cleanURL = (baseUrl: string): string => {
  let linkURL = baseUrl;
  if (baseUrl && baseUrl.indexOf('://') === -1) {
    linkURL = `http://${linkURL}`;
  }

  return linkURL || baseUrl;
};

export const logOut = async () => {
  removeUserToken();
  removeUserHash();
  removeUserEmail();
  removeUserName();
  // removeNewUser();
  if (!getUserName()) {
    navigate('/');
  }
};

export const openCalendly = () => {
  //@ts-ignore
  global.window &&
    //@ts-ignore
    window.Calendly &&
    //@ts-ignore
    window.Calendly.initPopupWidget({url: 'https://calendly.com/hexact/hexowatch-demo?hide_gdpr_banner=1'});
  return false;
};